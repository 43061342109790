import React from 'react';

import Layout from '../layout/index';

import SEO from '../components/seo/index';

import '../assets/styles/pages/faq.scss';

import aboutbg from '../assets/images/about-bg.svg';

import bookstay from '../assets/images/home/bookastay/bookastay.png';

// import { Carousel } from 'react-bootstrap';

const Bookastay = () => {
    return (
        <>
            <SEO title='Bookastay' />
            <Layout>
                <div >
                    <div className="abtimg">
                        <img
                            src={aboutbg}
                            alt="First slide"
                        />
                        <p className="text">Book a stay</p>
                    </div>
                </div>
                <div className="book-stay-sec">
                    <div className='col-md-12'>
                        <section className='section padding-abt'>
                            <div className='container'>

                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className="book-stay-container">
                                            <h6 className="card-text" style={{fontWeight:'bold'}}>Please provide the following information in order to book a stay</h6>
                                            <form>
                                                <div className="form-line-height">
                                                    <label htmlFor="name">Abhyasi ID</label><br />
                                                    <input type="text" className="bookastay-input" name="name" />
                                                </div>
                                                <div className="form-line-height">
                                                    <label htmlFor="arrival">Arrival Date</label><br />
                                                    <input type="text" className="bookastay-input" name="Arrival Date" />
                                                </div>
                                                <div className="form-line-height">
                                                    <label>Departure Date</label><br />
                                                    <input type="text" className="bookastay-input" name="Departure Date" />
                                                </div>
                                                <div className="form-line-height">
                                                    <label htmlFor="special">Any special constraints</label><br />
                                                    <input type="textarea" className="bookastay-input" name="Any special constraints" />
                                                </div>
                                                <div className="form-line-height">
                                                    <label htmlFor="meals">Meals requested</label><br />
                                                    <input type="textarea" className="bookastay-input" name="Meals requested" />
                                                </div>
                                                <div className="form-line-height">
                                                    <label htmlFor="phone">Phone Number</label><br />
                                                    <input type="textarea" className="bookastay-input" name="Phone Number" />
                                                </div>
                                                <div className="form-line-height">
                                                    <label htmlFor="emergency">Emergency contact Number</label><br />
                                                    <input type="textarea" className="bookastay-input" name="Emergency contact Number" />
                                                </div>
                                                <p className="card-text">Please note : only simple ashram cooked food is provided. Suggested donation : Rs 2000 per day.. Email to be sent to Heartfulness Corporates Gurugram corp.gurugram@heartfulness.org</p>
                                                <div>
                                                    <input type="submit" value="Donate" className="book-btn" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div>
                                            <img src={bookstay} width='569px' height='369px' />
                                        </div>
                                        <div>
                                            <section className="section">
                                                <h3 className="header-tag">Plan a trip</h3>
                                                <p className="about-text">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                </p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </Layout>
        </>
    );
};

export default Bookastay;
